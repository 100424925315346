import React, { ReactElement } from 'react'

import Layout from '@/components/layout'

function Page(): ReactElement {
  return (
    <Layout>
      <h1>Ischemisk trykk</h1>
      <p>
        Kiropraktoren behandler stive muskler ved å lokalisere triggerpunkter i muskelen og gir et manuelt press mot
        disse punktene. Kiropraktoren bryter på denne måten ned sammentrekninger i muskelvevet og muskelen slapper mer
        av, og funksjonen til muskelen blir bedre.
      </p>
    </Layout>
  )
}

export default Page
